<template>
    <div class="relative">
        <div class="w-full absolute block z-30">
            <Header/>
        </div>
        <div class="careers-banner-container space-top w-full">
            <div class="relative h-full w-full flex justify-center flex-col">
                <img class="absolute top-0 right-0 h-full object-cover z-5 lg:mr-12" src="../../../assets/images/careers-page/careers-banner.png" alt="Careers Banner">
                <div class="flex flex-col -mb-6 mobileL:mb-0 z-10 max-w-xs md:max-w-xl 2xl:max-w-3xl p-0 mobileL:p-4 text-start mobileL:text-center ml-0 lg:ml-16 2xl:ml-20">
                    <h1 class="careers-banner-title flex mobileL:static mobileL:justify-center ml-6 mobileS:text-2xl xs:text-3xl md:text-4xl lg:text-6xl 2xl:text-8xl text-white-1">
                    Careers
                    </h1>
                    <p class="mt-4 text-sm sm:text-base md:text-xl lg:text-xl xl:text-2xl 2xl:text-4xl px-4 text-white-1">
                        We make today’s talent, tomorrow’s success. We do this by 
                        setting in motion what is already in our name - B.A.R.E
                    </p>
                </div>
            </div>
        </div>  
        <div class="relative">
            <img class="absolute w-full h-full object-cover" src="../../../assets/images/home-page/plc-perks/update-bg.png">
            <div class="relative top-0 left-0 w-full mx-auto h-full flex justify-center items-center flex-col">
                <div class="text-white-1 text-xs mobileL:text-base lg:text-lg xl:text-xl 2xl:text-2xl w-full px-4 lg:w-5/6 2xl:pl-8 py-4 mobileL:py-8 leading-tight">
                    <div class="relative flex items-center mb-4 md:mb-6 lg:mb-8 2xl:mb-16 justify-center gap-10 mobileL:gap-6 lg:gap-10"> 
                        <h2 class="text-2xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl font-tahu w-1/6 lg:max-w-xs leading-none text-left">Bare</h2>
                        <p class="leading-tight">
                            Be part of a group of individuals all working 
                            together as one family to build something meaningful.
                        </p>
                    </div>
                    <div class="relative flex items-center mb-4 md:mb-6 lg:mb-8 2xl:mb-16 justify-center gap-6 lg:gap-10"> 
                        <h2 class="text-2xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl font-tahu w-1/6 lg:max-w-xs leading-none text-left">Advocate</h2>
                        <p class="leading-tight">
                            We are passionate about bringing Filipino ingenuity 
                            to the global beauty and wellness industry.
                        </p>
                    </div>
                    <div class="relative flex items-center mb-4 md:mb-6 lg:mb-8 2xl:mb-16 justify-center gap-6 lg:gap-10"> 
                        <h2 class="text-2xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl font-tahu w-1/6 lg:max-w-xs leading-none text-left">Reward</h2>
                        <p class="leading-tight">
                            As you pave your way towards an exciting future, every 
                            victory is celebrated and hard work fully rewarded.
                        </p>
                    </div>
                    <div class="relative flex items-center justify-center gap-6 lg:gap-10"> 
                        <h2 class="text-2xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl font-tahu w-1/6 lg:max-w-xs leading-none text-left">Empower</h2>
                        <p class="leading-tight">
                            Our culture of growth, ownership and transparency 
                            empowers our team to unlock their potential.
                        </p>
                    </div>
                </div>
            </div>
        </div> 
        <div class="relative flex">
            <img class="absolute top-0 left-0 w-full h-full object-cover z-0" src="../../../assets/images/careers-page/body-bg.png">
            <img class="relative top-0 left-0 w-52 md:w-96 lg:w-110 xl:w-120 2xl:w-130 h-full object-cover z-10" src="../../../assets/images/careers-page/careers-hq.png">
            <div class="absolute flex flex-col bg-gradient-to-t from-brown-8 items-end justify-center text-center w-full h-full mobileL:pr-16 lg:pr-20">
                <div class="items-center mr-4 lg:mr-12 2xl:mr-20 z-20">
                    <h2 class="font-semibold text-brown-4 text-4xl md:text-5xl lg:text-5xl xl:text-7xl 2xl:text-9xl leading-none">HEADQUARTER<br>ROLES</h2>  
                    <button @click="toggleTabs" class="rounded-full bg-brown-3 justify-center text-white-1 mt-1 mobileL:mt-4 py-2 px-6">
                        View Opportunities
                    </button>
                </div> 
            </div>  
        </div>

        <div v-if="showTabs" class="font-bold font-poppins text-white-1 text-xs leading-tight lg:leading-normal lg:text-lg flex items-center 
        justify-between py-2 gap-4 px-8 max-h-40 overflow-y-auto bg-green-9 2xl:text-2xl">
            <button @click="selectTab(1)" :class="{ 'active-tab': selectedTab === 1 }" class="tab-title">
                Marketing and Creatives Manager
            </button>
            <button @click="selectTab(2)" :class="{ 'active-tab': selectedTab === 2 }" class="tab-title">
                Business Development Officer
            </button>
            <button @click="selectTab(3)" :class="{ 'active-tab': selectedTab === 3 }" class="tab-title">
                Accounting Analyst
            </button>
            <button @click="selectTab(4)" :class="{ 'active-tab': selectedTab === 4 }" class="tab-title">
                Learning and Development Supervisor
            </button>
            <button @click="selectTab(5)" :class="{ 'active-tab': selectedTab === 5 }" class="tab-title">
                UI/UX Designer
            </button>
        </div>
        <div v-if="selectedTab !== null && showTabs" class="max-h-72 lg:max-h-130 overflow-y-auto">
            <div class="tab-content tab-content text-brown-4 bg-brown-1 px-10 mobileL:px-24 py-4">
                <div v-if="selectedTab === 1">
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Position Summary</h3>
                        <p class="text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            The Marketing and Creative Manager is responsible for the development and supervision 
                            of all marketing and communications strategies for the company. 
                            This individual will oversee the development of goals, strategies, and implementation 
                            plans to execute comprehensive marketing campaigns. 
                            The incumbent will promote the creation, enhancement and development of programs 
                            to promote the company and its services; strategically maintaining 
                            brand integrity while establishing the company's status as an industry leader. 
                            The Marketing and Creative Manager will also oversee the launch and 
                            managing of new products, services, and associated brands to meet profitability goals and targets.
                        </p>
                    </div>
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Work Experience</h3>
                        <p class="text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            Minimum of 5 years experience in a managerial role in digital marketing or 
                            advertising position. Has a solid experience in Marketing and Brand Management.
                        </p>
                    </div>
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Education</h3>
                        <p class="text-sm lg:text-xl 2xl:text-3xl leading-tight">
                            Minimum of 5 years experience in a managerial role in digital marketing or 
                            advertising position. Has a solid experience in Marketing and Brand Management.
                        </p>   
                    </div>
                </div>
                <div v-if="selectedTab === 2">
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Position Summary</h3>
                        <p class="text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            The Business Development Officer is responsible in managing account assignment, 
                            its day-to-day tasks, and develop and execute strategies 
                            to increase company sales and profits. The BD Officer is also assigned in 
                            analyzing opportunities to contribute to business growth by 
                            acquiring long-term partnerships, analyzing results and managing retention.
                        </p>
                    </div>
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Educational Background</h3>
                        <p class="text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            Graduate of any Business Course or any related study
                        </p>
                    </div>
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Experience Requirement</h3>
                        <p class="text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            With at least 2-3 years Sales and Marketing or Business Development experience
                        </p>
                    </div>
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Competency Requirement</h3>
                        <ul class="list-disc ml-8 text-sm lg:text-xl 2xl:text-3xl leading-tight">
                            <li>Thorough assessment of data</li>
                            <li>Concise report of progress or setbacks</li>
                            <li>Continuous implementation of initiatives</li>
                            <li>Continuous generation of ideas to contribute to business growth</li>
                            <li>Communication Skills</li>
                            <li>Presentation Skills</li>
                        </ul>
                    </div>
                </div>
                <div v-if="selectedTab === 3">
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Position Summary</h3>
                        <p class="text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            This Accounting Analyst position focuses on the accounting of branch 
                            sales and is mainly responsible for the timely monitoring and accurate 
                            recording of all collections deposited by the branches. The Analyst 
                            is also responsible for the reconciliation of bank transactions, 
                            and other tasks assigned by the Accounting Supervisor and/or Senior Finance Manager.
                        </p>
                    </div>
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Competency Requirement</h3>
                        <ul class="list-disc ml-8 text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            <li>With initiative and can work with minimum supervision</li>
                            <li>Independent and self-disciplined</li>
                            <li>Detailed and organized</li>
                            <li>With good accounting knowledge</li>
                            <li>Knowledgeable in MS Office especially Excel</li>
                        </ul>
                    </div>
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Experience Requirement</h3>
                        <p class="text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            1 year work experience
                        </p>
                    </div>
                </div>
                <div v-if="selectedTab === 4">
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Position Summary</h3>
                        <p class="text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            The L&D Supervisor applies instructional design strategies 
                            and methodologies, as well as content writing expertise, 
                            to deliver high-quality training solutions and content 
                            across all levels in the organization.
                        </p>
                    </div>
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Qualifications</h3>
                        <ul class="list-disc ml-8 text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            <li>Bachelor’s degree/certification in any of the following (or similar) 
                                fields is a plus, but not required: Educational Technology, Distance Education, 
                                Instructional Design, Journalism, Technical Writing, Copy writing 
                                English, Communication Arts, Mass Communication.</li>
                            <li>At least 2 years of work experience in the related field</li>
                            <li>Experience in any of the following fields is a plus: training delivery, 
                                content creation, e-learning development</li>
                            <li>Knowledge in systems and tools used in development of online training 
                                content. Knowledge of adult learning principles</li>
                        </ul>
                    </div>
                </div>
                <div v-if="selectedTab === 5">
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Position Summary</h3>
                        <p class="text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            UX Designer will design software and platforms that meet the project owner’s needs. 
                            Being analytical and creative is required for this role in 
                            order to meet the and grasp the needs of the project.
                        </p>
                    </div>
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Experience Requirement</h3>
                        <ul class="list-disc ml-8 text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            <li>1-3 years of experience as a UX designer or similar role.</li>
                            <li>Portfolio of designed projects.</li>
                        </ul>
                    </div>
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Qualifications</h3>
                        <ul class="list-disc ml-8 text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            <li>Creative and conceptual thinker</li>
                            <li>Accuracy and attention to detail</li>
                            <li>Proficient in photoshop, Adobe XD and other design and wireframing tools</li>
                            <li>Up-to-date with the latest UX trends, techniques and technologies</li>
                            <li>Ability to present work collaboratively as well as explaining rationales behind the design.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="relative flex">
            <img class="absolute top-0 md:-mb-4 xl:-mb-4 2xl:-mb-36 h-full w-full bg-gradient-to-t from-brown-8 object-cover z-0" src="../../../assets/images/careers-page/body-bg.png">
            <div class="relative ml-auto z-10">
                <img class="w-52 md:w-96 lg:w-110 xl:w-120 2xl:w-130 object-cover h-full z-10" src="../../../assets/images/careers-page/careers-salon.png">
            </div>
            <div class="absolute flex flex-col items-start justify-center mobileL:bg-gradient-to-t mobileL:from-brown-8 text-center w-full h-full mobileL:pl-16 lg:pl-20 z-20 mobileL:z-0">
                <div class="mobileL:items-center ml-6 md:ml-4 lg:ml-12 2xl:ml-20 z-20">
                    <h2 class="font-semibold text-brown-4 text-4xl md:text-5xl lg:text-5xl xl:text-7xl 2xl:text-9xl leading-none">SALON<br>OPPORTUNITIES</h2>  
                    <button @click="toggleSalonTabs" class="rounded-full bg-brown-3 justify-center text-white-1 mt-1 mobileL:mt-4 py-2 px-6">
                        View Opportunities
                    </button>
                </div> 
            </div>  
        </div>
        <div v-if="showSalonTabs" class="font-bold font-poppins text-white-1 text-sm mobileL:text-lg flex items-center justify-center py-2 gap-4 px-8 
        max-h-40 overflow-y-auto bg-green-9 2xl:text-2xl">
            <button @click="selectSalonTab(1)" :class="{ 'active-tab': selectedSalonTab === 1 }" class="tab-title ">
                Wax Professional
            </button>
        </div>
        <div v-if="selectedSalonTab !== null && showSalonTabs" class="">
            <div class="tab-content text-brown-4 bg-brown-1 px-10 mobileL:px-24 py-4">
                <div v-if="selectedSalonTab === 1">
                    <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Requirement</h3>
                    <ul class="list-disc ml-8 text-sm lg:text-xl 2xl:text-3xl leading-tight">
                        <li>Patient, trustworthy and customer service oriented with at least 1-2 
                            years of experience in the salon & spa industry.</li>
                        <li>Must be TESDA accredited or Certificate in Waxing services holder.</li>
                        <li>Highly competent in providing waxing services.</li>
                        <li>Willing to be trained in San Juan, Metro Manila.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="relative flex bg-brown-7 mobileL:pt-2">
            <img class="absolute lg:relative bottom-0 lg:top-0 w-64 mobileL:w-80 -left-20 lg:left-0 lg:w-110 2xl:w-120 ml-0 xl:ml-20 object-cover z-0" src="../../../assets/images/careers-page/life-at.png">
            <div class="lg:absolute text-brown-4 flex lg:right-0 flex-col ml-32 mobileL:ml-80 mr-4 mobileL:mr-12 lg:ml-0 lg:justify-center text-center md:w-4/6 lg:w-1/2 h-full lg:pr-0 lg:mr-20 py-2 md:py-4">
                <div class="items-center z-10">
                    <h2 class="careers-lifeatlaybare md:text-2xl lg:text-4xl 2xl:text-7xl md:mb-2 lg:mb-3 pb-2">#LifeAtLayBare</h2>
                    <div>
                        <img class="absolute h-4 md:h-8 lg:h-16 2xl:h-20 pl-2 lg:pl-0 -ml-2 mt-6 mobileL:mt-2" src="../../../assets/images/careers-page/quote 3.png" alt="Quotation">
                        <h4 class="font-semibold mobileL:text-lg lg:text-2xl 2xl:text-4xl mobileL:mb-2">Political Science Major</h4>
                        <div class="text-xs md:text-sm lg:text-xl 2xl:text-2xl leading-tight">
                            <p class="mb-4">
                                I spent 19 years of my career in people management, mostly in the food 
                                industry which surprised some people when I shifted to Personal Care. 
                                Before I joined Lay Bare, I also asked myself what could I offer the 
                                company given that my previous work experience is unrelated in some ways.
                            </p>
                            <p class="mb-4">
                                Then I figured out one of the various contributions I can give to Lay 
                                Bare and that is my passion to coach people and impart the wisdom that 
                                will help them achieve their goals. Working with Lay Bare did not only 
                                give me the opportunity to carry on with what I love doing, 
                                but it became an avenue for my personal growth as well.
                            </p>
                            <p class="mb-4">
                                Every day is a learning process and it gives me great confidence and 
                                pride in being part of the organization as I witness the company's 
                                journey to progress. I am grateful to work with managers, support team, 
                                and my dearest Operations team for they make the day-to-day engagement truly worthwhile.
                            </p>
                        </div>
                        <img class="absolute h-4 md:h-8 lg:h-16 2xl:h-20 mr-4 mobileL:mr-12 lg:mr-0 right-0 -mt-8 mobileL:-mt-12" src="../../../assets/images/careers-page/quote 2.png">
                        <h3 class="mt-4 font-semibold mobileL:text-lg lg:text-2xl 2xl:text-4xl">Ms. Catherine Crucillo</h3>
                        <span class="font-semibold text-sm lg:text-xl 2xl:text-3xl">Senior Operations Manager</span>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="relative bg-brown-4 py-4 mobileL:py-8 lg:py-12 text-white-1">
            <div class="mx-auto mobileL:w-5/6 px-3 mobile:px-0 md:gap-12 lg:gap-24 2xl:gap-32 flex text-sm md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl">
                <div class="flex-1 text-center flex flex-col items-center pt-10 mobileL:pt-0">
                    <h2 class="font-tahu text-3xl md:text-4xl lg:text-6xl 2xl:text-7xl leading-none">Our Mission</h2>
                    <p class="py-4">We encourage everyone to make bold choices in beauty and in life.</p>
                </div>
                <div class="absolute border-r border-white h-5/6 left-1/3 ml-1 mt-3 mobileL:mt-0 mobileL:ml-7"></div>
                <div class="flex-1 text-center flex flex-col items-center pt-10 mobileL:pt-0">
                    <h2 class="font-tahu text-3xl md:text-4xl lg:text-6xl 2xl:text-7xl">Our Vision</h2>
                    <p class="p-4">We empower our clients through our services and our people with new opportunities.</p>
                </div>
                <div class="absolute border-r border-white h-5/6 left-2/3 mt-3 mobileL:mt-0 -ml-2 mobileL:-ml-8 lg:-ml-9"></div>
                <div class="flex-1 text-center flex flex-col items-center">
                    <h2 class="font-tahu text-3xl md:text-4xl lg:text-6xl 2xl:text-7xl leading-none">Our Purpose</h2>
                    <p class="2xl:px-4">We are a wellbeing beauty brand that inspires 
                        confidence and unlocks one's full potential 
                        through our expertise and the power of human touch. 
                    </p>
                </div>
            </div>
        </div>
        <div class="w-full absolute block z-20">
            <Footer/>
        </div>
    </div>
</template>

<script>
import Header from '../../../components/navigation/LaybareHeader.vue'
import Footer from '../../../components/footer/LaybareFooter.vue'

export default {
    components: {
        Header,Footer
    },
    mounted() {
        this.$gtag.pageview('/laybare/career')
    },
    data() {
        return {
        showTabs: false,
        selectedTab: null,
        showSalonTabs: false,
        selectedSalonTab: null
        };
    },
    methods: {
        toggleTabs() {
            this.showTabs = !this.showTabs;
        },
        toggleSalonTabs() {
            this.showSalonTabs = !this.showSalonTabs
        },
        selectTab(tabNumber) {
            this.selectedTab = this.selectedTab === tabNumber ? null : tabNumber;
        },
        selectSalonTab(tabNumber) {
            this.selectedSalonTab = this.selectedSalonTab === tabNumber ? null : tabNumber;
        },
    },
}
</script>

<style>
.careers-banner-container {
    height: 700px;
    background-size: cover;
    background: linear-gradient(180deg, #F2D0B7 0%, #D9AC8D 100%);
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .careers-banner-container {
        height: 45dvh;
    }
}

.space-top {
  margin-top: 90px;
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
    }
}

@media (max-width:480px) {
    .careers-banner-container{
        height:255px;
    }
    .space-top {
        margin-top: 60px;
    }
}

@media (min-width: 1536px) {
    .careers-banner-container {
      height: 110vh;
    }
    .space-top {
        margin-top: 128px;
    }
}

.careers-banner-title {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 0px #C58965;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: 600;
}

.careers-lifeatlaybare {
    text-align: center;
    text-shadow: 0px 3px 0px #D2AF87;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: 600;
}

@media only screen and (min-width: 768px) {
    .careers-banner-title {
        text-shadow: 0px 6px 0px #C58965;
    }
    .careers-lifeatlaybare {
        text-shadow: 0px 5px 0px #D2AF87;
    }
}

@media only screen and (min-width: 992px) {
    .careers-banner-title {
        text-shadow: 0px 8px 0px #C58965;
    }
    .careers-lifeatlaybare {
        text-shadow: 0px 6px 0px #D2AF87;
    }
}

@media only screen and (min-width: 1200px) {
    .careers-banner-title {
        text-shadow: 0px 10px 0px #C58965;
    }
    .careers-lifeatlaybare {
        text-shadow: 0px 8px 0px #D2AF87;
    }
}

.tab-item {
  flex: 1;
  padding: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  margin-right: 8px;
}

.active-tab {
  color: #67983F;
}
</style>
